<template>
  <div
    id="map"
    :style="{ width: '100%', height: '100%', borderRadius: '5px', border: '2px solid #686868' }"
  >
    <button
      v-if="map"
      class="btn-map-attribution"
      @mousedown="showAttributionHandler"
    />
    <button
      v-if="map&& $store.state.use_main_map_rotation"
      class="btn-map-rotate"
      @mousedown="rotateMap"
    >
      <v-icon
        color="black"
      >
        mdi-format-rotate-90
      </v-icon>
    </button>
    <p style="
        color: #7f7f7f;
        float: right;
    ">loading... </p>
  </div>
</template>

<script>
import L from 'leaflet-rotate-map';
import "leaflet/dist/leaflet.css";
import 'leaflet-rotatedmarker';
import EventBus from '@/main.js'
import * as math3d from 'math3d';
import * as THREE from 'three';
import slow from '@/assets/audio/slow.mpeg';
import defaultPos from '@/assets/image/marker/default_pos.png';
import directPos from '@/assets/image/marker/direct_pos.png';
import chargePos from '@/assets/image/marker/charge_pos.png';
import loaderLoadPos from '@/assets/image/marker/loader_load_pos.png';
import loaderUnloadPos from '@/assets/image/marker/loader_unload_pos.png';

export default {
  name: 'Map',
  props: {
    mode: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      tf: null,
      data: null,
      width: null,
      height: null,
      resolution: null,
      x: 0,
      y: 0,
      z: 0,
      map: null,
      mapURL: null,
      overlay: null,

      obstacleId: null,
      obstacles: [],
      areaId: null,
      areas: [],

      status: [],
      robot: null,
      direction: null,
      path: null,

      circleMarker: null,

      newObstacle: null,
      newArea: null,
      gridLayer: null,
      bearing: 0,

      taskName: null,
      taskLocation: null,
      taskInfoOverlay: null,

      locations: [],
      locationId: null,
      locationName: null,
      newLocationCircle: null,
      modifyingLocationCircle: null,
      modifyingLocationCircleName: null,
      attribution: {
        battery: '-',
        emg: 'OFF',
        bumper: 'OFF',
        servo: 'ON',
        dockingStatus: '-',
        hostname: '-',
        moveResult: '-',
        sys_power: '-',
        input: '-',
        output: '-',
        time: '-',
        speed: '-',
        load: '-',
        roll: '-',
        pitch: '-',
        yaw: '-',
      },
      showAttribution: false,
      isSoundPlaying: false,
      robotCenterCircle: [],
      lidarMarkers: [],
      scaleControl: null,
    }
  },
  computed: {
    model(){
      return this.$store.state.model;
    },
  },
  created() {
    if(this.mode=== 'simple'){
      EventBus.$on('useGrid', this.useGrid);
      EventBus.$on('useLidar', this.useLidar);
      EventBus.$on('useRadius', this.useRadius);
      EventBus.$on('useScale', this.useScale);
      setTimeout(function(scope){
        scope.initSimple();
      },500, this);
      return;
    }

    this.initEventBus();
    this.initialize();
  },
  beforeDestroy() {
    EventBus.$off('addWall');
    EventBus.$off('cancelAddWall');
    EventBus.$off('saveWall');
    EventBus.$off('deleteWall');

    EventBus.$off('addArea');
    EventBus.$off('cancelAddArea');
    EventBus.$off('saveArea');
    EventBus.$off('deleteArea');

    EventBus.$off('clearTaskInfo');

    EventBus.$off('addLocation');
    EventBus.$off('cancelAddLocation');
    EventBus.$off('saveLocation');
    EventBus.$off('robotSaveLocation');
    EventBus.$off('deleteLocation');
    EventBus.$off('cancelModifyingLocation');

    EventBus.$off('moveLocation');
    EventBus.$off('saveModifiedLocation');

    EventBus.$off('lockLocationPopup');
    EventBus.$off('unLockLocationPopup');

    EventBus.$off('findLocation');
    EventBus.$off('cancelFindLocation');

    EventBus.$off('useGrid');
    EventBus.$off('useLidar');
    EventBus.$off('useRadius');
    EventBus.$off('useScale');

    // socket list
    EventBus.$off('robot:enterArea/map');
    EventBus.$off('robot:tf/map');
    EventBus.$off('robot:globalPlan/map');
    EventBus.$off('robot:result/map');
    EventBus.$off('robot:map/map');
    EventBus.$off('robot:battery/map');
    EventBus.$off('robot:load/map');
    EventBus.$off('robot:emg/map');
    EventBus.$off('robot:bumper/map');
    EventBus.$off('robot:roll/map');
    EventBus.$off('robot:pitch/map');
    EventBus.$off('robot:yaw/map');
    EventBus.$off('robot:dockingStatus/map');
    EventBus.$off('robot:gpio/map');
    EventBus.$off('robot:sys_power/map');
    EventBus.$off('robot:time/map');
    EventBus.$off('robot:speed/map');
    EventBus.$off('job:goto_location_id/map');
    EventBus.$off('job:job_finish/map');

    EventBus.$off('lidar/map');

    EventBus.$off('socketConnected');
  },
  methods: {
    initialize() {
      this.attribution.hostname = location.hostname;
      this.fetchData()
        .then(()=>{
          if(this.data.info){
            this.mapInit();
            this.fetchLocations()
              .then(this.fetchObstacles)
              .then(this.fetchAreas)
              .then(this.createRobot)
              .catch (err => { throw Error(err); });
          }else{
            setTimeout(() => {
              this.initialize();
            }, 1000);
          }
        })
    },
    initSimple() {
      EventBus.$on('lidar/map', this.setScanData);
      EventBus.$on('robot:pitch/map', (data)=> {
        this.attribution.pitch = data.pitch? data.pitch.toString().substr(0, 5): '0.000';
        this.updateSimpleAttributionControl();
      });

      this.width = 1000;
      this.height = 1000;
      this.resolution = 0.05;
      this.x = Math.floor(this.width/2);
      this.y = Math.floor(this.height/2);

      if (this.map) this.map.remove();
      this.map = L.map('map', {
        minZoom: 2,
        maxZoom: 10,
        crs: L.CRS.Simple,
        padding: 10,
        maxBounds: new L.LatLngBounds(
          new L.LatLng(100, 100),
          new L.LatLng(this.width-100, this.height-100),
        ),
      }).setView([this.y, this.x], 5);

      const canvas = this.getCanvas(
        this.width,
        this.height,
        new Array(this.width* this.height).fill(0),
        255,
      );

      if (typeof canvas !== 'undefined') {
        const scaleX = this.resolution * this.width;
        const scaleY = this.resolution * this.height;

        if (this.overlay) {
          this.overlay.setUrl(canvas.toDataURL());
          this.overlay.setBounds([ [0, 0], [scaleY, scaleX] ])
        } else {
          this.overlay = L.imageOverlay(
            canvas.toDataURL(),
            [ [0, 0], [scaleY, scaleX] ],
            { interactive: true }
          ).addTo(this.map);
          this.map.fitBounds([[0, 0], [scaleY, scaleX]]);
        }
      }

      const res= {data: {
        translation:{
          x: 0,
          y: 0,
          z: 0,
        },
        rotation:{
          x: 0,
          y: 0,
          z: 0.707,
          w: 0.707,
        },
      }};

      this.tf= res.data;
      const t = this.getRobotPosition(res.data.translation, res.data.rotation);
      if(this.$store.state.map_option_radius) this.createCenterCircle(this.y + t.localPosition.y, this.x + t.localPosition.x);

      this.drawRobot(t);
      const target = L.latLng(this.y + t.localPosition.y, this.x + t.localPosition.x);
      this.map.setView(target, 5);

      if(this.$store.state.map_option_scale) this.scaleControl= L.control.scale({imperial:false}).addTo(this.map);
      if(this.$store.state.map_option_grid) this.showGridLayer();

      this.map.zoomControl.setPosition('bottomright');
      this.map.attributionControl.setPrefix(`Ver.${this.$store.state.version}_${this.$store.state.model}`);
      this.map.attributionControl.setPosition('topleft');
    },
    initEventBus(){
      EventBus.$on('addWall', this.addObstacle);
      EventBus.$on('cancelAddWall', () => {
        this.map.off('click');
        if (this.circleMarker) {
          this.map.removeLayer(this.circleMarker);
        }
        if (this.newObstacle) {
          this.map.removeLayer(this.newObstacle);
        }
        this.map.removeLayer(this.gridLayer);
        this.mapEnable();
        this.createObstacles();
      });
      EventBus.$on('saveWall', () => {
        this.saveObstacle();
        this.map.removeLayer(this.gridLayer);
        this.mapEnable();
      });
      EventBus.$on('deleteWall', this.removeObstacle);

      EventBus.$on('addArea', this.addArea);
      EventBus.$on('cancelAddArea', () => {
        this.map.off('click');
        if (this.circleMarker) {
          this.map.removeLayer(this.circleMarker);
        }
        if (this.newArea) {
          this.map.removeLayer(this.newArea);
        }
        this.map.removeLayer(this.gridLayer);
        this.mapEnable();
        this.createAreas();
      });
      EventBus.$on('saveArea', (speed) => {
        this.saveArea(speed);
        this.map.removeLayer(this.gridLayer);
        this.mapEnable();
      });
      EventBus.$on('deleteArea', this.removeArea);

      EventBus.$on('clearTaskInfo', () => {
        return this.$axios.get('/job')
          .then(res => {
            if (res.status === 200) {
              this.taskName = null;
              if (this.taskInfoOverlay) {
                this.map.removeLayer(this.taskInfoOverlay);
              }
            }
          })
          .catch(err => { throw Error(err); });
      });

      EventBus.$on('addLocation', () => {
        this.locationId = null;
        this.addLocation();
      });
      EventBus.$on('cancelAddLocation', () => {
        this.map.removeLayer(this.gridLayer);
        if (this.newLocationCircle) { this.map.removeLayer(this.newLocationCircle); }
        this.newLocationCircle = null;
        this.map.off('click');
        this.mapEnable();
        this.createLocations();
      });
      EventBus.$on('saveLocation', (data) => {
        this.saveLocation(data);
        this.map.removeLayer(this.gridLayer);
        if (this.newLocationCircle) { this.map.removeLayer(this.newLocationCircle); }
        this.newLocationCircle = null;
        this.map.off('click');
        this.mapEnable();
      });
      EventBus.$on('robotSaveLocation', () => {
        this.fetchLocations().catch(err => { throw Error(err); });
      });
      EventBus.$on('deleteLocation', this.removeLocation);
      EventBus.$on('cancelModifyingLocation', () => {
        this.locationId = null;
        this.createLocations();
      });

      EventBus.$on('moveLocation', this.moveLocation);
      EventBus.$on('saveModifiedLocation', data => {
        this.saveModifiedLocation(data);
      });

      EventBus.$on('lockLocationPopup', () => {
        this.locationId = null;
        const notBindingPopup = false;
        this.createLocations(notBindingPopup);
      });
      EventBus.$on('unLockLocationPopup', () => {
        this.createLocations();
      });

      EventBus.$on('findLocation', this.setFindRobotLocation);
      EventBus.$on('cancelFindLocation', () => {
        this.map.off('click');
      });

    },
    initSocket () {
      EventBus.$off('robot:enterArea/map');
      EventBus.$off('robot:tf/map');
      EventBus.$off('robot:globalPlan/map');
      EventBus.$off('robot:result/map');
      EventBus.$off('robot:map/map');
      EventBus.$off('robot:battery/map');
      EventBus.$off('robot:load/map');
      EventBus.$off('robot:emg/map');
      EventBus.$off('robot:bumper/map');
      EventBus.$off('robot:roll/map');
      EventBus.$off('robot:pitch/map');
      EventBus.$off('robot:yaw/map');
      EventBus.$off('robot:dockingStatus/map');
      EventBus.$off('robot:gpio/map');
      EventBus.$off('robot:sys_power/map');
      EventBus.$off('robot:time/map');
      EventBus.$off('robot:speed/map');
      EventBus.$off('job:goto_location_id/map');
      EventBus.$off('job:job_finish/map');
      EventBus.$off('lidar/map');

      EventBus.$off('socketConnected');
      EventBus.$on('socketConnected', (data)=>{
        if(this.map&& !data) this.map.attributionControl.setPrefix(`offline`);
        if(this.map&& data) this.map.attributionControl.setPrefix(`online - wait for data`);
      });

      EventBus.$on('robot:enterArea/map', ()=> {
        if(this.isSoundPlaying) return;
        this.isSoundPlaying= true;
        let slowAudio= new Audio(slow);
        slowAudio.play();
        setTimeout(()=> this.isSoundPlaying= false, 2000);
      });

      EventBus.$on('robot:tf/map', (data)=> {
        this.tf = data;
        this.updateRobot(data);
      });

      EventBus.$on('robot:globalPlan/map', (data)=> {
        if (this.path === null) {
          const latLng = data.globalPlan.map(el => [el.pose.position.y + this.y, el.pose.position.x + this.x]);
          this.path = L.polyline(latLng).addTo(this.map);
        } else {
          return this.pathUpdate(data.globalPlan);
        }
      });

      EventBus.$on('robot:result/map', (data)=> {
        if (!data || !data.result) return;
        this.status = data.result;
        this.attribution.moveResult = this.status.status.status;
        if (this.attribution.moveResult === 3) { EventBus.$emit('robotStatus'); }
        this.updateAttributionControl();
        if (this.attribution.moveResult === 2 || this.attribution.moveResult === 3) {
          if (this.path) {
            this.path.remove();
            this.path = null;
          }
        }
      });

      EventBus.$on('robot:map/map', (data)=> {
        this.data = data;
        this.width = this.data.info.width;
        this.height = this.data.info.height;
        this.resolution = this.data.info.resolution;
        this.x = -this.data.info.origin.position.x;
        this.y = -this.data.info.origin.position.y;
        this.z = this.data.info.origin.position.z;
        this.drawMap();
        this.createRobot();
      });

      EventBus.$on('robot:battery/map', (data)=> {
        this.attribution.battery = data.battery;
        this.updateAttributionControl();
      });

      EventBus.$on('robot:load/map', (data)=> {
        this.attribution.load = data.load;
        this.updateAttributionControl();
      });

      EventBus.$on('robot:emg/map', (data)=> {
        this.attribution.emg = (data.emg === 1) ? 'ON' : 'OFF';
        this.updateAttributionControl();
      });

      EventBus.$on('robot:bumper/map', (data)=> {
        // 0:off, 1:범퍼만 2:스위치킴 3:스위치범퍼둘다
        this.attribution.bumper = (data.bumper === 1 || data.bumper === 3) ? 'ON' : 'OFF';
        this.attribution.servo = (data.bumper === 2 || data.bumper === 3) ? 'OFF' : 'ON';
        this.updateAttributionControl();
      });

      EventBus.$on('robot:roll/map', (data)=> {
        this.attribution.roll = data.roll? data.roll.toString().substr(0, 5): '0.000';
        this.updateAttributionControl();
      });

      EventBus.$on('robot:pitch/map', (data)=> {
        this.attribution.pitch = data.pitch? data.pitch.toString().substr(0, 5): '0.000';
        this.updateAttributionControl();
      });

      EventBus.$on('robot:yaw/map', (data)=> {
        this.attribution.yaw = data.yaw? data.yaw.toString().substr(0, 5): '0.000';
        this.updateAttributionControl();
      });

      EventBus.$on('robot:dockingStatus/map', (data)=> {
        this.attribution.dockingStatus = data.status.data;
        this.updateAttributionControl();
      });

      EventBus.$on('robot:gpio/map', (data)=> {
        this.attribution.input = [data.gpio['Input0'], data.gpio['Input1'], data.gpio['Input2'], data.gpio['Input3'], data.gpio['Input4'], data.gpio['Input5'], data.gpio['Input6'], data.gpio['Input7']].toString();
        this.attribution.output = [data.gpio['Output0'], data.gpio['Output1'], data.gpio['Output2'], data.gpio['Output3'], data.gpio['Output4'], data.gpio['Output5'], data.gpio['Output6'], data.gpio['Output7']].toString();
        this.updateAttributionControl();
      });

      EventBus.$on('robot:sys_power/map', (data)=> {
        const num = Number(data.sys_power);
        const result = num / 10;
        // 10으로 나눈뒤 소수점 한자리까지 표출
        this.attribution.sys_power = Math.round(result * 10) / 10;
        this.updateAttributionControl();
      });

      EventBus.$on('robot:time/map', (data)=> {
        this.attribution.time = /\s\d\d:\d\d(?=:)/.exec(data)[0];
        this.updateAttributionControl();
      });

      EventBus.$on('robot:speed/map', (data)=> {
        this.attribution.speed = data.speed? data.speed.toString().substr(0, 4): '0.00';
        this.updateAttributionControl();
      });

      if (this.mode === 'task') {
        EventBus.$on('job:goto_location_id/map', (data)=> {
          const workId = data.split("/")[1];
          const locationId = parseInt(data.split("/")[0]);

          this.$axios.get(`/work/${workId}`)
            .then(res => {
              if (res.status === 200) {
                this.taskName = res.data[0].work_name;
                EventBus.$emit('showTaskName', this.taskName);
              }
            })
            .then(() => {
              this.locations.forEach(location => {
                if (location.location_id === locationId) {
                  this.taskLocation = location.location_name;
                  this.putTaskInfo();
                }
              });
            })
            .catch(err => { throw Error(err) });
        });

        EventBus.$on('job:job_finish/map', ()=> {
          setTimeout(() => {
            this.$axios.get('/job')
              .then(res => {
                if (res.status === 200) {
                  if (!res.data.length) {
                    this.taskName = null;
                    this.map.removeLayer(this.taskInfoOverlay)
                  }
                }
              })
              .catch(err => { throw Error(err); });
          }, 500);
        });
      }

      EventBus.$on('lidar/map', this.setScanData);
    },
    updateSimpleAttributionControl(){
      if (this.map) {
        if (this.showAttribution) {
          this.map.attributionControl.setPrefix(`
            ${this.$store.state.use_imu_pitch?`<span>Pitch:${this.attribution.pitch}</span><br>`: ''}
            <div style="margin: 0.3rem 0;">
              <hr style="width: 30%; margin: 0 auto;">
            </div>
          `);
        } else {
          this.map.attributionControl.setPrefix(`
            <span>Ver.${this.$store.state.version}_${this.$store.state.model}</span><br>
            <div style="margin: 0.3rem 0;">
              <hr style="width: 30%; margin: 0 auto;">
            </div>
          `);
        }

        const attributionBtn = document.querySelector(".btn-map-attribution");
        if (attributionBtn) {
          const statusDIV = document.querySelector(".leaflet-control-attribution");
          attributionBtn.style.width = getComputedStyle(statusDIV).width;
          attributionBtn.style.height = getComputedStyle(statusDIV).height;
        }
      }
    },
    updateAttributionControl () {
      if (this.map) {
        if (this.showAttribution) {
          this.map.attributionControl.setPrefix(`
            <span>Battery:${this.attribution.battery}%</span><br>
            ${this.$store.state.use_move_result?`<span>MoveResult:${this.attribution.moveResult}</span><br>`: ''}
            ${this.$store.state.use_emg_signal?`<span>Emg:${this.attribution.emg}</span><br>`: ''}
            ${this.$store.state.use_bumper_signal?`<span>Bumper:${this.attribution.bumper}</span><br>`: ''}
            ${this.$store.state.use_servo_signal?`<span>Servo:${this.attribution.servo}</span><br>`: ''}
            ${this.$store.state.use_docking_signal?`<span>DockingStatus:${this.attribution.dockingStatus}</span><br>`: ''}
            ${this.$store.state.use_robot_ip?`<span>Ip:${this.attribution.hostname}</span><br>`: ''}
            ${this.$store.state.use_system_power?`<span>SystemPower:${this.attribution.sys_power}</span><br>`: ''}
            ${this.$store.state.use_gpio?`<span>Input:${this.attribution.input}</span><br>`: ''}
            ${this.$store.state.use_gpio?`<span>Output:${this.attribution.output}</span><br>`: ''}
            ${this.$store.state.use_speed?`<span>Speed: ${this.attribution.speed} m/s</span><br>`: ''}
            ${this.$store.state.use_load_signal?`<span>LoadStatus: ${this.attribution.load} </span><br>`: ''}
            ${this.$store.state.use_imu_roll?`<span>Roll:${this.attribution.roll}</span><br>`: ''}
            ${this.$store.state.use_imu_pitch?`<span>Pitch:${this.attribution.pitch}</span><br>`: ''}
            ${this.$store.state.use_imu_yaw?`<span>Yaw:${this.attribution.yaw}</span><br>`: ''}
            ${this.$store.state.use_system_time?`<span>SystemTime:${this.attribution.time}</span>`: ''}
            <div style="margin: 0.3rem 0;">
              <hr style="width: 30%; margin: 0 auto;">
            </div>
          `);
        } else {
          this.map.attributionControl.setPrefix(`
            <span>Ver.${this.$store.state.version}_${this.$store.state.model}</span><br>
            <span>Battery:${this.attribution.battery}%</span><br>
            <div style="margin: 0.3rem 0;">
              <hr style="width: 30%; margin: 0 auto;">
            </div>
          `);
        }

        const attributionBtn = document.querySelector(".btn-map-attribution");
        if (attributionBtn) {
          const statusDIV = document.querySelector(".leaflet-control-attribution");
          attributionBtn.style.width = getComputedStyle(statusDIV).width;
          attributionBtn.style.height = getComputedStyle(statusDIV).height;
        }
      }
    },
    update() {
      return this.fetchData()
        .then(this.fetchLocations)
        .then(this.fetchObstacles)
        .then(this.fetchAreas)
        .then(this.drawMap)
        .catch (err => { throw Error(err); });
    },
    fetchData() {
      return this.$axios.get('/map')
        .then(res => {
          if (res.status === 200) {
            this.data = res.data;
            if(this.data.info){
              this.width = this.data.info.width;
              this.height = this.data.info.height;
              this.resolution = this.data.info.resolution;
              this.x = -this.data.info.origin.position.x;
              this.y = -this.data.info.origin.position.y;
              this.z = this.data.info.origin.position.z;
            }
          }
        });
    },
    fetchLocations() {
      return this.$axios.get('/location')
        .then(res => {
          if (res.status === 200) {
            this.locations = res.data;
            if (this.mode !== 'mapping' && this.locations.length) {
              this.createLocations();
            } else {
              this.clearLocations();
            }
          }
        });
    },
    fetchObstacles() {
      return this.$axios.get('/obstacle')
        .then(res => {
          if (res.status === 200) {
            this.obstacles = res.data;
            if (this.mode !== 'mapping' && this.obstacles.length) {
              this.createObstacles();
            } else {
              this.clearObstacles();
            }
          }
        });
    },
    fetchAreas() {
      return this.$axios.get('/area')
        .then(res => {
          if (res.status === 200) {
            this.areas = res.data;
            if (this.mode !== 'mapping' && this.areas.length) {
              this.createAreas();
            } else {
              this.clearAreas();
            }
          }
        })
    },
    mapInit() {
      if (this.map) this.map.remove();
      this.map = L.map('map', {
        minZoom: 0,
        maxZoom: 10,
        crs: L.CRS.Simple,
        padding: 10,
        rotate: true
      }).setView([this.y, this.x], 5);

      if(this.$store.state.map_option_scale) this.scaleControl= L.control.scale({imperial:false}).addTo(this.map);
      if(this.$store.state.map_option_grid) this.showGridLayer();

      this.map.zoomControl.setPosition('bottomright');
      this.map.attributionControl.setPrefix(`Ver.${this.$store.state.version}_${this.$store.state.model}`);
      this.map.attributionControl.setPosition('topleft');

      if (this.mode === 'map' || this.mode === 'mapping' || this.mode === 'task' || this.mode === 'location' || this.mode === 'wall' || this.mode === 'area') {
        this.initSocket();
      }

      if (this.mode !== 'mapping'){
        this.drawMap();
        this.map.setBearing(this.bearing);
      }
    },
    rotateMap() {
      this.bearing -= 90;
      if (this.bearing <= -360) this.bearing = 0;
      this.map.setBearing(this.bearing);
    },
    drawMap() {
      const canvas = this.getCanvas(this.width, this.height, this.data.data, 255);
      this.setMapData(canvas);
    },
    drawRobot(transform, isUpdate) {
      const t1 = new math3d.Transform();
      const t2 = new math3d.Transform();
      const t3 = new math3d.Transform();
      const t4 = new math3d.Transform();
      const halfWidth= this.$store.state.robot_width/2;
      const topHeight= this.$store.state.robot_top_height;
      const bottomHeight= this.$store.state.robot_bottom_height;
      t1.parent = transform;
      t2.parent = transform;
      t3.parent = transform;
      t4.parent = transform;
      t1.localPosition = transform.localPosition;
      t2.localPosition = transform.localPosition;
      t3.localPosition = transform.localPosition;
      t4.localPosition = transform.localPosition;
      t1.translate(new math3d.Vector3(topHeight, halfWidth, 0));
      t2.translate(new math3d.Vector3(-bottomHeight, halfWidth, 0));
      t3.translate(new math3d.Vector3(-bottomHeight, -halfWidth, 0));
      t4.translate(new math3d.Vector3(topHeight, -halfWidth, 0));

      // direction of robot
      const d1 = new math3d.Transform();
      const d2 = new math3d.Transform();
      const d3 = new math3d.Transform();
      d1.parent = transform;
      d2.parent = transform;
      d3.parent = transform;
      d1.localPosition = transform.localPosition;
      d2.localPosition = transform.localPosition;
      d3.localPosition = transform.localPosition;
      d1.translate(new math3d.Vector3(0.15, 0, 0));
      d2.translate(new math3d.Vector3(-0.1, -0.15, 0));
      d3.translate(new math3d.Vector3(-0.1, 0.15, 0));

      const robotPoint1 = [this.y + t1.localPosition.y, this.x + t1.localPosition.x];
      const robotPoint2 = [this.y + t2.localPosition.y, this.x + t2.localPosition.x];
      const robotPoint3 = [this.y + t3.localPosition.y, this.x + t3.localPosition.x];
      const robotPoint4 = [this.y + t4.localPosition.y, this.x + t4.localPosition.x];

      const directionPoint1 = [this.y + d1.localPosition.y, this.x + d1.localPosition.x];
      const directionPoint2 = [this.y + d2.localPosition.y, this.x + d2.localPosition.x];
      const directionPoint3 = [this.y + d3.localPosition.y, this.x + d3.localPosition.x];

      if(isUpdate){
        this.robot.setLatLngs([robotPoint1, robotPoint2, robotPoint3, robotPoint4]);
        this.direction.setLatLngs([directionPoint1, directionPoint2, directionPoint3]);
        return;
      }

      this.robot = L.rectangle([robotPoint1, robotPoint2, robotPoint3, robotPoint4], {
        color: '#41bfb4',
        weight: 1,
        customType: 'robot'
      }).addTo(this.map);

      this.direction = L.polygon([directionPoint1, directionPoint2, directionPoint3], {
        color: '#000000',
        weight: 1,
        customType: 'robot'
      }).addTo(this.map);
    },
    getCanvas(width, height, mapData, maxValue) {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = width;
      canvas.height = height;
      if (ctx !== null) {
        const img = ctx.getImageData(0, 0, width, height);
        for (let row = 0; row < (height-1); row++) {
          for (let col = 0; col < (width-1); col++) {
            const mapI = col + ((height - row) * width);
            const pos = ((row * width) + col) * 4;
            let data = mapData[mapI];
            if (data === 100) {
              data = 0;
            } else if (data === 0) {
              data = 255;
            } else if (data >= 67 && data <= 99) {
              data = 50;
            } else if (data >= 51 && data <= 66) {
              data = 100;
            } else if (data >= 34 && data <= 50) {
              data = 150;
            } else if (data >= 1 && data <= 33) {
              data = 200;
            } else {
              data = 127;
            }
            img.data[pos] = data;
            img.data[pos + 1] = data;
            img.data[pos + 2] = data;
            img.data[pos + 3] = maxValue;
          }
        }
        ctx.putImageData(img, 0, 0);
        return canvas;
      }
    },
    setMapData(canvas) {
      if (typeof canvas !== 'undefined') {
        const scaleX = this.resolution * this.width;
        const scaleY = this.resolution * this.height;

        if (this.overlay) {
          this.overlay.setUrl(canvas.toDataURL());
          this.overlay.setBounds([ [0, 0], [scaleY, scaleX] ])
        } else {
          this.overlay = L.imageOverlay(
            canvas.toDataURL(),
            [ [0, 0], [scaleY, scaleX] ],
            { interactive: true }
          ).addTo(this.map);
          this.map.fitBounds([[0, 0], [scaleY, scaleX]]);
        }

        if (this.mode !== 'mapping' && this.locations.length) {
          this.createLocations();
        }
        if (this.mode !== 'mapping' && this.obstacles.length) {
          this.createAreas();
        }
        if (this.mode !== 'mapping' && this.areas.length) {
          this.createObstacles();
        }

      }
    },
    clearRobot() {
      if (!this.map) return;
      this.map.eachLayer((layer) => {
        if (layer.options.customType === 'robot') this.map.removeLayer(layer);
      });
    },
    createRobot() {
      this.clearRobot();
      return this.$axios.get('/nav')
        .then(res => {
          if (res.status === 200) {
            this.tf= res.data;
            const t = this.getRobotPosition(res.data.translation, res.data.rotation);
            if(this.$store.state.map_option_radius) this.createCenterCircle(this.y + t.localPosition.y, this.x + t.localPosition.x);

            this.drawRobot(t);
            const target = L.latLng(this.y + t.localPosition.y, this.x + t.localPosition.x);

            if (this.mode !== 'mapping') return this.map.setView(target, 5);
            this.map.setView(target);
          }
        })
        .catch(err => { throw Error(err); });
    },
    getRobotPosition(vector, rotation) {
      const Vector3 = math3d.Vector3;
      const Quaternion = math3d.Quaternion;
      const Transform = math3d.Transform;
      return new Transform(
        new Vector3(vector.x, vector.y, vector.z),
        new Quaternion(rotation.x, rotation.y, -rotation.z, rotation.w),
      );
    },
    updateRobot(data) {
      if(!this.robot) return;
      const t = this.getRobotPosition(data.translation, data.rotation);
      if(this.$store.state.map_option_radius) this.updateCenterCircle(this.y + t.localPosition.y, this.x + t.localPosition.x);

      this.drawRobot(t, true);

      if(data.load<= 0){
        this.robot.setStyle({
          color: '#41bfb4',
        });
      }else{
        this.robot.setStyle({
          color: '#ff0000',
        });
      }

      if (this.mode === 'task') {
        if (this.taskInfoOverlay) {
          this.taskInfoOverlay.setBounds([
            [this.robot.getBounds()._northEast.lat, this.robot.getBounds()._northEast.lng - 1],
            [this.robot.getBounds()._southWest.lat - 1.5, this.robot.getBounds()._southWest.lng + 1]
          ]);
        } else {
          this.putTaskInfo();
        }
      }
    },
    putTaskInfo() {
      if(!this.robot) {
        setTimeout(this.putTaskInfo, 1000);
        return;
      }
      if (this.taskName === null) {
        return;
      }
      if (this.taskInfoOverlay) {
        this.map.removeLayer(this.taskInfoOverlay);
      }
      const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgElement.setAttribute('xmlns', "http://www.w3.org/2000/svg");
      svgElement.setAttribute('viewBox', "0 0 200 200");

      if (this.taskLocation === null) {
        svgElement.innerHTML = '<text x="100" y="105" font-size="20" text-anchor="middle">' +
        '<tspan x="100" y="70">' + this.taskName + '</tspan>' + '</text>';
      } else {
        svgElement.innerHTML = '<text x="100" y="105" font-size="20" text-anchor="middle">' +
        '<tspan x="100" y="70">' + this.taskName + '</tspan>' +
        '<tspan x="100" y="100" fill="#00BFB4">' + this.taskLocation + '</tspan>' + ' 이동중' + '</text>';
      }

      // svg 위치 고정 (가변X)
      const svgElementBounds = [
        [this.robot.getBounds()._northEast.lat, this.robot.getBounds()._northEast.lng - 1],
        [this.robot.getBounds()._southWest.lat - 1.5, this.robot.getBounds()._southWest.lng + 1]
      ];
      this.taskInfoOverlay = L.svgOverlay(svgElement, svgElementBounds, {
        customType: 'robot'
      }).addTo(this.map);
    },
    clearLocations() {
      if (!this.map) return;
      this.map.eachLayer((layer) => {
        if (layer.options.customType === 'location') this.map.removeLayer(layer);
      });
    },
    getDegree(qx, qy, qz, qw) {
      const quaternion = new THREE.Quaternion(qx, qy, -qz, qw);
      const euler = new THREE.Euler();
      euler.setFromQuaternion(quaternion);

      return euler.z*(180/Math.PI);
    },
    createCenterCircle(robot_x, robot_y){
      if(this.robotCenterCircle[0]) this.map.removeLayer(this.robotCenterCircle[0]);
      if(this.robotCenterCircle[1]) this.map.removeLayer(this.robotCenterCircle[1]);
      if(this.robotCenterCircle[2]) this.map.removeLayer(this.robotCenterCircle[2]);
      if(this.robotCenterCircle[3]) this.map.removeLayer(this.robotCenterCircle[3]);

      this.robotCenterCircle[0]= L.circle([robot_x, robot_y], {
        color: '#ffc1cc',
        radius: 5,
        opacity: 0.3,
      }).addTo(this.map);
      this.robotCenterCircle[1]= L.circle([robot_x, robot_y], {
        color: '#ffc1cc',
        radius: 10,
        opacity: 0.3,
      }).addTo(this.map);
      this.robotCenterCircle[2]= L.circle([robot_x, robot_y], {
        color: '#ffc1cc',
        radius: 15,
        opacity: 0.3,
      }).addTo(this.map);
      this.robotCenterCircle[3]= L.circle([robot_x, robot_y], {
        color: '#ffc1cc',
        radius: 20,
        opacity: 0.3,
      }).addTo(this.map);
    },
    updateCenterCircle(robot_x, robot_y){
      if(this.robotCenterCircle[0]) this.robotCenterCircle[0].setLatLng([robot_x, robot_y]);
      if(this.robotCenterCircle[1]) this.robotCenterCircle[1].setLatLng([robot_x, robot_y]);
      if(this.robotCenterCircle[2]) this.robotCenterCircle[2].setLatLng([robot_x, robot_y]);
      if(this.robotCenterCircle[3]) this.robotCenterCircle[3].setLatLng([robot_x, robot_y]);
    },
    createLocations(popup = true) {
      this.clearLocations();
      this.locations.forEach((location) => {
        let circle;
        let deg = this.getDegree(location.location_position_qx, location.location_position_qy, location.location_position_qz, location.location_position_qw);
        if (location.location_id === this.locationId) {
          circle = L.marker([location.location_position_y + this.y, location.location_position_x + this.x], {
            rotationAngle: deg,
            zIndexOffset: 9999,
            icon: L.icon({
              iconUrl: defaultPos,
              iconSize: [18, 12],
              iconAnchor: [9, 6],
            }),
            customLid: location.location_id,
            customType: 'location',
            customName: location.location_name,
            opacity: 0.8,
          });

          this.modifyingLocationCircle = circle;
        } else {
          let iconUrl = directPos;
          switch (location.location_type){
            case 'DOCKING': iconUrl = chargePos; break;
            case 'LOAD': iconUrl = loaderLoadPos; break;
            case 'UNLOAD': iconUrl = loaderUnloadPos; break;
          }
          circle = L.marker([location.location_position_y + this.y, location.location_position_x + this.x], {
            rotationAngle: deg,
            zIndexOffset: 9999,
            icon: L.icon({
              iconUrl: iconUrl,
              iconSize: [18, 12],
              iconAnchor: [9, 6],
            }),
            customLid: location.location_id,
            customType: 'location',
            customName: location.location_name,
            opacity: 0.8,
          });
        }

        if (this.mode === 'location' && popup) {
          const popup = document.createElement("div");
          const popup_remove = document.createElement("h1");
          const popup_modify = document.createElement("h1");
          popup.append(popup_remove, popup_modify);
          popup.classList.add("col");
          popup.classList.add("text-center");

          const popup_button_remove = document.createElement("button");
          popup_remove.appendChild(popup_button_remove);
          popup_button_remove.innerHTML = "삭제";
          popup_button_remove.addEventListener('click', () => {
            this.map.closePopup();
            EventBus.$emit('showRemoveLocationModal', this.locationName);
          });

          const popup_button_modify = document.createElement("button");
          popup_modify.appendChild(popup_button_modify);
          popup_button_modify.innerHTML = "수정";
          popup_button_modify.addEventListener('click', () => {
            this.map.closePopup();
            this.setModifyLocation(this.locationId);
          });

          circle.bindPopup(popup, {
            closeButton: false,
            className: 'popup-remove'
          }).on('click', (evt) => {
            this.locationId = evt.target.options.customLid;
            this.locationName = evt.target.options.customName;
          }).addTo(this.map);
        } else {
          circle.addTo(this.map);
        }
        const svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElement.setAttribute('xmlns', "http://www.w3.org/2000/svg");
        svgElement.setAttribute('viewBox', "0 0 200 200");
        svgElement.innerHTML = '<text x="100" y="105" font-size="30" text-anchor="middle">' + location.location_name + '</text>';
        const svgElementBounds = [
          [circle.getLatLng().lat, circle.getLatLng().lng - 1],
          [circle.getLatLng().lat - 0.7, circle.getLatLng().lng + 1]
        ];

        if (location.location_id === this.locationId) {
          this.modifyingLocationCircleName = L.svgOverlay(svgElement, svgElementBounds, {
            customType: 'location'
          }).addTo(this.map);
        } else {
          L.svgOverlay(svgElement, svgElementBounds, {
            customType: 'location'
          }).addTo(this.map);
        }
      });
    },
    setModifyLocation(locationId) {
      // TO DO: locationId를 가진 circle 을 지우고 다른 색으로 새로 그려놓을것
      const notBindingPopup = false;
      this.createLocations(notBindingPopup);
      EventBus.$emit('toModifyLocation', locationId);
    },
    clearObstacles() {
      if (!this.map) return;
      this.map.eachLayer((layer) => {
        if (layer.options.customType === 'obstacle') this.map.removeLayer(layer);
      });
    },
    createObstacles(popup = true) {
      this.clearObstacles();
      this.obstacles.forEach(el => {
        const latlng1 = [el.ObstaclePoints[0].obstacle_point_y + this.y, el.ObstaclePoints[0].obstacle_point_x + this.x];
        const latlng2 = [el.ObstaclePoints[2].obstacle_point_y + this.y, el.ObstaclePoints[2].obstacle_point_x + this.x];

        //popup click event 추가
        if (this.mode === 'wall' && popup) {
          const popup = document.createElement("h1");
          const popup_button = document.createElement("button");
          popup.appendChild(popup_button);
          popup_button.innerHTML = "삭제하기";
          popup_button.addEventListener('click', () => {
            this.map.closePopup();
            EventBus.$emit('showRemoveObstacleModal');
          });

          L.rectangle([latlng1, latlng2], {
            color: '#f4301d',
            weight: 1,
            customOID: el.obstacle_id,
            customType: 'obstacle'
          }).bindPopup(popup, {
            closeButton: false,
            className: 'popup-remove'
          }).on('click', (evt) => {
            this.obstacleId = evt.target.options.customOID;
          }).addTo(this.map);

        } else {
          L.rectangle([latlng1, latlng2], {
            color: '#f4301d',
            weight: 1,
            customOID: el.obstacle_id,
            customType: 'obstacle'
          }).addTo(this.map);
        }
      });

      if (this.newObstacle) {
        this.map.removeLayer(this.newObstacle);
      }

    },
    clearAreas() {
      if (!this.map) return;
      this.map.eachLayer((layer) => {
        if (layer.options.customType === 'area') this.map.removeLayer(layer);
      });
    },
    createAreas(popup = true) {
      this.clearAreas();
      this.areas.forEach(el => {
        const latlng1 = [el.Area_points[0].area_point_y + this.y, el.Area_points[0].area_point_x + this.x];
        const latlng2 = [el.Area_points[2].area_point_y + this.y, el.Area_points[2].area_point_x + this.x];

        if (this.mode === 'area' && popup) {
          const popup = document.createElement("h1");
          const popup_button = document.createElement("button");
          popup.appendChild(popup_button);
          popup_button.innerHTML = "삭제하기";
          popup_button.addEventListener('click', () => {
            this.map.closePopup();
            EventBus.$emit('showRemoveAreaModal');
          });

          L.rectangle([latlng1, latlng2], {
              color: '#f7fa69',
              weight: 1,
              customOID: el.area_id,
              customType: 'area'
            }).bindPopup(popup, {
              closeButton: false,
              className: 'popup-remove'
            }).on('click', (evt) => {
              this.areaId = evt.target.options.customOID;
            }).addTo(this.map);

        } else {
          L.rectangle([latlng1, latlng2], {
            color: '#f7fa69',
            weight: 1,
            customOID: el.area_id,
            customType: 'area'
          }).addTo(this.map);
        }
      });
      if (this.newArea) {
        this.map.removeLayer(this.newArea);
      }
    },
    pathUpdate(plan) {
      this.path.setLatLngs(plan.map(el => [el.pose.position.y + this.y, el.pose.position.x + this.x]));
    },
    addObstacle() {
      const notBindingPopup = false;
      this.createObstacles(notBindingPopup);
      this.mapDisable();
      this.showGridLayer();
      this.drawSquare('reDrawWall');
    },
    addArea() {
      const notBindingPopup = false;
      this.createAreas(notBindingPopup);
      this.mapDisable();
      this.showGridLayer();
      this.drawSquare('reDrawArea');
    },
    addLocation() {
      const notBindingPopup = false;
      this.createLocations(notBindingPopup);
      this.mapDisable();
      this.drawCircle();
      this.showGridLayer();
    },
    moveLocation() {
      this.mapDisable();
      this.moveCircle();
      this.showGridLayer();
    },
    mapDisable() {
      this.map.scrollWheelZoom.disable();
      this.map.dragging.disable();
      this.map.touchZoom.disable();
      this.map.doubleClickZoom.disable();
      this.map.boxZoom.disable();
      this.map.keyboard.disable();
      this.map.zoomControl.disable();
    },
    mapEnable() {
      this.map.scrollWheelZoom.enable();
      this.map.dragging.enable();
      this.map.touchZoom.enable();
      this.map.doubleClickZoom.enable();
      this.map.boxZoom.enable();
      this.map.keyboard.enable();
      this.map.zoomControl.enable();
    },
    showGridLayer() {
      const gridLayer = L.GridLayer.extend({
        createTile: function () {
          const tile = document.createElement('div');
          tile.style.outline = '1px solid #393939';
          return tile;
        },
        options: {
          tileSize: 100,
          opacity: 0.3
        }
      });

      this.gridLayer = function(opts) {
        return new gridLayer(opts);
      }();
      this.gridLayer.addTo(this.map);
      this.map.getPane('tilePane').style.zIndex = 650;
    },
    drawSquare(event) {
      const rectanglePoint = [];

      this.map.on('click', (e) => {
        if (rectanglePoint.length < 2) {
          rectanglePoint.push([e.latlng.lat, e.latlng.lng]);
          if (rectanglePoint.length === 1) {
            this.circleMarker = L.circleMarker(rectanglePoint[0], {
              weight: 1,
            }).addTo(this.map);
          } else if (rectanglePoint.length === 2) {
            this.map.removeLayer(this.circleMarker);

            if (this.mode === 'wall') {
              this.newObstacle = L.rectangle(rectanglePoint, {
                color: '#f4301d',
                weight: 1
              }).addTo(this.map);
            } else if (this.mode === 'area') {
              this.newArea = L.rectangle(rectanglePoint, {
                color: '#f7fa69',
                weight: 1
              }).addTo(this.map);
            }

            this.map.off('click');

            EventBus.$emit(event);
          }
        }
      });
    },
    drawCircle() {
      this.map.on('click', (e) => {
        if (this.newLocationCircle) {
          this.map.removeLayer(this.newLocationCircle);
          this.newLocationCircle = null;
        }
        this.newLocationCircle = L.circle([e.latlng.lat, e.latlng.lng],  {
          radius: 0.2,
          color: '#3232ff',
          opacity: 1,
        }).addTo(this.map);
        EventBus.$emit('locationCircle');
      });
    },
    moveCircle() {
      this.map.on('click', (e) => {
        this.modifyingLocationCircle.setLatLng(e.latlng);
        const svgElementBounds = [
          [this.modifyingLocationCircle.getLatLng().lat, this.modifyingLocationCircle.getLatLng().lng - 1],
          [this.modifyingLocationCircle.getLatLng().lat - 0.7, this.modifyingLocationCircle.getLatLng().lng + 1]
        ];
        this.modifyingLocationCircleName.setBounds(svgElementBounds);
      })
    },
    saveObstacle() {
      const points = [];
      this.newObstacle.getLatLngs()[0].forEach((el, i) => {
        points.push({
          "obstacle_point_x": el.lng - this.x,
          "obstacle_point_y": el.lat - this.y,
          "obstacle_point_z": 0,
          "obstacle_point_order": i
        });
      });

      return this.$axios.post('/obstacle', {
        "obstacle_type": "POLYGON",
        "ObstaclePoints": points
      })
        .then(this.fetchObstacles)
        .catch(err => { throw Error(err); });
    },
    removeObstacle() {
      return this.$axios.delete(`/obstacle/${this.obstacleId}`, {
        obstacleId: this.obstacleId
      })
        .then(this.fetchObstacles)
        .catch(err => { throw Error(err); });
    },
    saveArea(speed) {
      const points = [];

      this.newArea.getLatLngs()[0].forEach((el, i) => {
        points.push({
          "area_point_x": el.lng - this.x,
          "area_point_y": el.lat - this.y,
          "area_point_z": 0,
          "area_point_order": i
        });
      });

      return this.getMapId()
        .then(id => {
          return this.$axios.post('/area', {
            map_id: id || 0,
            area_speed: speed,
            area_alert: "N",
            Area_points: points
          })
        })
        .then(this.fetchAreas)
        .catch(err => { throw Error(err); });
    },
    getMapId() {
      return this.$axios.get('/info')
        .then(res => {
          if (res.status === 200) {
            return res.data.map_id;
          }
        });
    },
    removeArea() {
      return this.$axios.delete(`/area/${this.areaId}`, {
        areaId: this.areaId
      })
        .then(this.fetchAreas)
        .catch(err => { throw Error(err); });
    },
    async saveLocation(data) {
      const { name, degree, type, autodocking, docking_id } = data;

      const Quaternion = math3d.Quaternion;
      const q = new Quaternion.Euler(0, 0, parseInt(degree));

      const { lat, lng } = this.newLocationCircle.getLatLng();

      const param = {
        locationName: name,
        locationType: type,
        location_position_x: lng - this.x,
        location_position_y: lat - this.y,
        location_position_z: 0,
        qx: 0,
        qy: 0,
        qz: q.z,
        qw: q.w,
        autodocking: autodocking,
        docking_id: docking_id,
      };
      await this.$axios.post('/location/mapLocation', param);
      await this.fetchLocations();
    },
    removeLocation() {
      return this.$axios.delete(`/location/${this.locationId}`, {
        locationId: this.locationId
      })
        .then(this.fetchLocations)
        .then(() => {
          EventBus.$emit('updateLocations');
        })
        .catch(err => { throw Error(err); });
    },
    saveModifiedLocation(data) {
      const { name, degree, type, autodocking, docking_id } = data;

      const Quaternion = math3d.Quaternion;
      const q = new Quaternion.Euler(0, 0, parseInt(degree));

      const { lat, lng } = this.modifyingLocationCircle.getLatLng();

      const param = {
        location_name: name,
        location_type: type,
        location_position_x: lng - this.x,
        location_position_y: lat - this.y,
        location_position_z: 0,
        qx: 0,
        qy: 0,
        qz: q.z,
        qw: q.w,
        autodocking: autodocking,
        docking_id: docking_id
      };
      return this.$axios.put(`/location/${this.locationId}`, param)
        .then(() => {
          this.locationId = null;
        })
        .then(this.fetchLocations)
        .catch(err => { throw Error(err); });
    },
    setFindRobotLocation() {
      this.map.on('click', (e) => {
        this.findRobotLocation(e.latlng.lng, e.latlng.lat);
      });
    },
    findRobotLocation(x, y) {
      EventBus.$emit('popLoadingEstimate');
      return this.$axios.put('/nav/poseEstimate', {
        x: x - this.x,
        y: y - this.y
      })
        .then(() => {
          this.map.off('click');
          EventBus.$emit('offFinding');
        })
        .catch(err => { throw Error(err); });
    },
    showAttributionHandler() {
      this.showAttribution = !this.showAttribution;
    },
    setScanData(data){
      if(!this.tf|| !this.$store.state.map_option_lidar) return;
      const t1 = new math3d.Transform();
      const t = this.getRobotPosition(this.tf.translation, this.tf.rotation);
      t1.parent = t;
      t1.localPosition = t.localPosition;
      t1.translate(new math3d.Vector3(this.$store.state.lidar_offset_x, this.$store.state.lidar_offset_y, 0));
      const theta= this.getDegree(this.tf.rotation.x, this.tf.rotation.y, this.tf.rotation.z, this.tf.rotation.w, )* Math.PI/ -180;

      for (let i = 0; i < data.ranges.length; i++) {
        if(this.lidarMarkers[i]) this.map.removeLayer(this.lidarMarkers[i]);
        const angle = data.angle_min + i * data.angle_increment;
        const distance = data.ranges[i];
        if(!distance) continue;
        const x = this.x + t1.localPosition.x + distance * Math.cos(angle + theta);
        const y = this.y + t1.localPosition.y + distance * Math.sin(angle + theta);
        if(!x && !y) continue;
        this.lidarMarkers[i] = L.circleMarker([y, x], {
          radius: 1,
          color: '#ff7777',
        }).addTo(this.map);
      }
    },
    useGrid(isUse){
      if(!isUse&& this.gridLayer) return this.map.removeLayer(this.gridLayer);
      this.showGridLayer();
    },
    useLidar(isUse){
      if(!isUse) {
        for (let i = 0; i < this.lidarMarkers.length; i++) {
          if(this.lidarMarkers[i]) this.map.removeLayer(this.lidarMarkers[i]);
        }
      }
    },
    useRadius(isUse){
      if(!isUse) {
        if(this.robotCenterCircle[0]) this.map.removeLayer(this.robotCenterCircle[0]);
        if(this.robotCenterCircle[1]) this.map.removeLayer(this.robotCenterCircle[1]);
        if(this.robotCenterCircle[2]) this.map.removeLayer(this.robotCenterCircle[2]);
        if(this.robotCenterCircle[3]) this.map.removeLayer(this.robotCenterCircle[3]);
        return;
      }
      const t = this.getRobotPosition(this.tf.translation, this.tf.rotation);
      this.createCenterCircle(this.y + t.localPosition.y, this.x + t.localPosition.x);
      this.clearRobot();
      this.drawRobot(t);
    },
    useScale(isUse){
      if(this.scaleControl) this.scaleControl.remove();
      if(!isUse) return;
      this.scaleControl= L.control.scale({imperial:false}).addTo(this.map);
    },
  },
}
</script>

<style>
.leaflet-map-pane {
  transform-origin: center;
  width: 100%;
  height: 100%;
}
.leaflet-overlay-pane {
  transform-origin: center;
  width: 100%;
  height: 100%;
}

.leaflet-control-attribution {
  background: rgba(0, 0, 0, 0.4) !important;
  color: #ffffff;
  padding: 0.2rem 0.5rem;
}

</style>
<style scoped>
.leaflet-container {
  background-color: #7f7f7f;
}

.btn-map-attribution {
  position: absolute;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 5rem;
  height: 1rem;
}

.btn-map-rotate {
  position: absolute;
  z-index: 2000;
  bottom: 2vh;
  left: 1vw;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  border: 2px solid #686868;
  background-color: #ffffff;
}

.btn-map-rotate:active {
  background-color: #88c9e7;
}

.btn-map-rotate > .v-icon {
  font-size: 22px !important;
}
</style>
